<template>
  <v-card
    flat
    tile
    class="overflow-auto"
    :class="$vuetify.breakpoint.mdAndUp ? 'flexcard' : ''"
    height="100%"
  >
    <v-card-title class="pb-2" style="padding-left: 15px"
      ><v-row
        ><v-col>{{ taskName }}</v-col>
        <v-col cols="1" class="d-flex justify-end py-0 mt-0">
          <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            class="my-auto"
            aria-label="cross"
            icon
            @click.native="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            v-else
            class="my-auto"
            aria-label="delete"
            icon
            @click="deleteDialog = true"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pa-0 grow">
      <v-container class="px-4 pt-0 mb-16">
        <v-row no-gutters align-content="center">
          <v-col cols="auto">
            <v-progress-circular
              class="mt-1"
              :rotate="-90"
              :size="30"
              :width="3"
              :value="progressValue"
              color="green"
            ></v-progress-circular>
          </v-col>
          <v-col cols="auto" class="select-view">
            <v-select
              v-model="progressValue"
              :items="taskProgressList"
              :menu-props="{ bottom: true, offsetY: true }"
              solo
              flat
              dense
              hide-details
              class="pa-0 fit"
            >
            </v-select>
          </v-col>
          <v-col cols="auto" class="mt-1">
            <span class="mr-2 text-subtitle-1 text--darken-2 font-weight-medium"
              >|</span
            >
            <v-icon size="18px">mdi-calendar-blank-outline</v-icon>
            <span
              class="text--darken-2 font-weight-medium"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'font-size:14px'
                  : 'font-size:10px'
              "
              :class="$vuetify.breakpoint.mdAndUp ? 'mx-2' : ''"
              >{{ $t("created") }}:</span
            >
            <span class="text-body-2 text--darken-2 font-weight-bold">{{
              createdDate
            }}</span>
          </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row no-gutters class="pt-4">
          <span
            class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
            >{{ $t("editDetails") }}</span
          >
        </v-row>
        <v-row no-gutters class="mt-4">
          <v-text-field
            v-model="taskName"
            outlined
            dense
            required
            :disabled="!checkPermission('createTask')"
            :error-messages="taskNameErrors"
            @input="$v.taskName.$touch()"
            @blur="$v.taskName.$touch()"
            ><template v-slot:label>
              <span class="red--text"> *</span> {{ $t("taskName") }}
            </template></v-text-field
          >
        </v-row>
        <v-row no-gutters>
          <v-autocomplete
            v-model="selectedTaskAssignees"
            :items="taskAssigneesList"
            :menu-props="{ bottom: true, offsetY: true }"
            label="Assigned To"
            outlined
            dense
            required
            :disabled="!checkPermission('createTask')"
            multiple
            item-text="name"
            item-value="user_id"
            @input="searchInput = null"
            :search-input.sync="searchInput"
          >
            <template v-slot:prepend-item v-if="taskAssigneesList.length !== 0">
              <v-list-item>
                <v-text-field
                  v-model="searchInput"
                  prepend-inner-icon="mdi-magnify"
                  :label="$t('searchName')"
                  single-line
                  hide-details
                  flat
                  variant="underlined"
                  dense
                  clear-icon="mdi-close-circle"
                  clearable
                >
                </v-text-field>
              </v-list-item>
              <v-list-item v-if="showAll">
                <v-checkbox
                  class="my-3"
                  hide-details
                  v-model="allAssignees"
                  @click="toggle"
                  label="Select All"
                >
                  <template v-slot:label>
                    <div
                      class="ml-6 font-weight-medium grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("all") }}
                    </div>
                  </template>
                </v-checkbox>
              </v-list-item>
            </template>
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                size="small"
                density="compact"
                v-bind="attrs"
                :input-value="selected"
              >
                <span class="pr-2"> {{ item.name }}</span>
                <v-icon small @click="parent.selectItem(item)">
                  mdi-close-circle
                </v-icon>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-row>
        <v-row no-gutters>
          <v-select
            v-model="selectedPriority"
            :items="taskPriorityList"
            :menu-props="{ bottom: true, offsetY: true }"
            label="Priority"
            outlined
            :disabled="!checkPermission('createTask')"
            dense
            required
          >
          </v-select>
        </v-row>
        <v-row no-gutters>
          <v-autocomplete
            v-model="selectedAssociateTicket"
            :items="associateTicketList"
            :menu-props="{ bottom: true, offsetY: true }"
            label="Associate Ticket"
            outlined
            dense
            required
            multiple
            item-text="ticket_number"
            item-value="ticket_id"
            @input="searchTicket = null"
            :search-input.sync="searchTicket"
          >
            <template
              v-slot:prepend-item
              v-if="associateTicketList.length !== 0"
            >
              <v-list-item>
                <v-text-field
                  v-model="searchTicket"
                  prepend-inner-icon="mdi-magnify"
                  :label="$t('searchticketNumber')"
                  single-line
                  hide-details
                  flat
                  dense
                  variant="underlined"
                  clear-icon="mdi-close-circle"
                  clearable
                >
                </v-text-field>
              </v-list-item>
              <v-list-item v-if="showAllTickets">
                <v-checkbox
                  class="my-3"
                  hide-details
                  v-model="allTickets"
                  @click="toggleTicket"
                  label="Select All"
                >
                  <template v-slot:label>
                    <div
                      class="ml-6 font-weight-medium grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("all") }}
                    </div>
                  </template>
                </v-checkbox>
              </v-list-item>
            </template>
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                size="small"
                density="compact"
                v-bind="attrs"
                :input-value="selected"
              >
                <span class="pr-2">
                  {{ item.ticket_number }}
                </span>
                <v-icon small @click="parent.selectItem(item)">
                  mdi-close-circle
                </v-icon>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-row>
        <v-row no-gutters justify="space-between">
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? 'auto' : '12'">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="scheduledStartDate"
                  persistent-hint
                  outlined
                  dense
                  prepend-inner-icon="mdi-calendar"
                  v-bind="attrs"
                  hide-details
                  v-on="on"
                  :disabled="!checkPermission('createTask')"
                  @change="emptyEndDate"
                  ><template v-slot:label>
                    {{ $t("startDate") }}
                  </template></v-text-field
                >
              </template>
              <v-date-picker
                v-model="date"
                no-title
                :min="minDate"
                @change="emptyEndDate"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            :cols="$vuetify.breakpoint.mdAndUp ? 'auto' : '12'"
            :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-4'"
          >
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
              nudge-left="60px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="scheduledEndDate"
                  persistent-hint
                  outlined
                  dense
                  prepend-inner-icon="mdi-calendar"
                  v-bind="attrs"
                  hide-details
                  v-on="on"
                  :disabled="!checkPermission('createTask')"
                  ><template v-slot:label>
                    {{ $t("endDate") }}
                  </template></v-text-field
                >
              </template>
              <v-date-picker
                v-model="endDate"
                no-title
                :min="date ? minEndDate : ''"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row no-gutters class="mt-6">
          <v-textarea
            v-model="description"
            rows="3"
            outlined
            row-height="20"
            no-resize
            dense
            hide-details
            ><template v-slot:label>
              {{ $t("description") }}
            </template></v-textarea
          >
        </v-row>
        <v-row>
          <v-app-bar
            elevation="0"
            color="white"
            class="appbar-header"
            height="40"
          >
            <v-tabs>
              <v-tab
                v-for="(key, index) in tabs"
                :key="index"
                class="text-none"
                :class="$vuetify.breakpoint.mdAndUp ? 'pl-8' : 'pl-5'"
                @click="changeTab(key)"
              >
                {{ $t(key) }}
              </v-tab>
            </v-tabs>
          </v-app-bar>
          <v-container>
            <v-card elevation="0" v-show="currentTab === 'toDoList'">
              <v-card-text class="pa-0">
                <ToDoTask :edit-task-details="editTaskDetails"> </ToDoTask>
              </v-card-text>
            </v-card>

            <v-card v-show="currentTab === 'tickets'">
              <v-card-text>
                <!-- Content specific to tab2 -->
                <p>Content for Tickets</p>
              </v-card-text>
            </v-card>

            <v-card v-show="currentTab === 'attachments'">
              <v-card-text>
                <!-- Content specific to tab3 -->
                <UploadFiles
                  :reference-id="task_id"
                  :show-header="false"
                  reference-type="task"
                />
              </v-card-text>
            </v-card>
            <v-card v-if="currentTab === 'history'">
              <TaskHistory :task-id="task_id" />
              <v-card-text>
                <!-- Content specific to tab3 -->
              </v-card-text>
            </v-card>
          </v-container>
        </v-row>
      </v-container>
    </v-card-text>

    <v-divider class="my-2 mx-4"></v-divider>

    <v-card-actions v-if="checkPermission('createTask')" class="mx-2 mb-3">
      <v-btn
        color="error"
        outlined
        class="mr-2 text-none rounded-lg elevation-0"
        width="110"
        @click="deleteDialog = true"
      >
        {{ $t("deleteTask") }}
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        outlined
        class="mr-2 text-none rounded-lg elevation-0"
        width="90"
        @click="closeDialog"
      >
        {{ $t("cancel") }}
      </v-btn>

      <v-btn
        color="primary"
        width="160"
        depressed
        class="text-none rounded-lg elevation-0"
        :disabled="$v.$invalid"
        @click="updatetask"
        ><span class="white--text text-none">{{ $t("updateChanges") }}</span>
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else class="mx-2 mb-3">
      <v-btn
        color="primary"
        outlined
        class="mr-2 text-none rounded-lg elevation-0"
        width="90"
        @click="closeDialog"
      >
        {{ $t("close") }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        width="90"
        depressed
        class="text-none rounded-lg elevation-0"
        :disabled="$v.$invalid"
        @click="updatetask"
        ><span class="white--text text-none">{{ $t("update") }}</span>
      </v-btn>
    </v-card-actions>
    <v-bottom-navigation
      v-if="$vuetify.breakpoint.smAndDown"
      fixed
      horizontal
      height="70"
      class="px-2 py-4"
      ><v-row justify="space-between" no-gutters>
        <v-btn
          color="primary"
          outlined
          class="mr-2 text-none rounded-lg elevation-0"
          width="50%"
          @click="closeDialog"
        >
          {{ checkPermission("createTask") ? $t("cancel") : $t("close") }}
        </v-btn>
        <v-btn
          color="primary"
          class="text-none font-weight-regular grey--text rounded-lg elevation-0"
          width="50%"
          depressed
          :disabled="$v.$invalid"
          @click="updatetask"
          ><span class="white--text text-none">{{
            checkPermission("createTask") ? $t("updateChanges") : $t("update")
          }}</span>
        </v-btn>
      </v-row></v-bottom-navigation
    >
    <TaskDoneDialog
      :dialog="doneDialog"
      @completeTask="completeTask"
      @closeDialog="closeDoneDialog"
    />
    <v-dialog
      v-if="deleteDialog"
      v-model="deleteDialog"
      max-width="420"
      max-height="400"
      persistent
    >
      <v-card class="overflow-hidden">
        <v-row class="ma-0 ml-4">
          <span
            class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >{{ $t("deleteTask") }}</span
          >
          <v-btn
            class="col-2 my-auto"
            aria-label="cross"
            icon
            @click="closeDeleteDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>
        <v-row class="ma-0 ml-4 mb-10 pt-4">
          <span
            class="grey--text text--darken-4 text-subtitle-1 font-weight-regular"
            >{{ $t("deleteTaskMessage") }}</span
          >
        </v-row>
        <v-row class="mb-2">
          <v-divider></v-divider>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            width="90"
            @click="closeDeleteDialog"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn
            class="primary text-none rounded-lg elevation-0"
            width="90"
            depressed
            @click="deleteTask()"
          >
            {{ $t("delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import api from "../../../store/api";
import UserDataMixin from "@/mixins/UserData";
import moment from "moment";

export default {
  components: {
    TaskDoneDialog: () => import("./TaskDoneDialog.vue"),
    ToDoTask: () => import("./ToDoTask.vue"),
    TaskHistory: () => import("./TaskHistory.vue"),
    UploadFiles: () => import("./UploadFiles.vue"),
  },
  name: "AddTask",
  mixins: [validationMixin, UserDataMixin],
  validations() {
    return {
      taskName: { required },
    };
  },
  data() {
    return {
      description: "",
      taskName: "",
      menu1: false,
      menu2: false,
      scheduledStartDate: "",
      scheduledEndDate: "",
      createdDate: "",
      searchInput: null,
      doneDialog: false,
      searchTicket: null,
      selectedTaskAssignees: [],
      selectedPriority: "",
      selectedAssociateTicket: [],
      centers: [],
      allAssignees: false,
      showAll: true,
      showAllTickets: true,
      allTickets: false,
      date: "",
      endDate: "",
      progressValue: "0%",
      deleteDialog: false,
      taskProgressList: ["0%", "25%", "50%", "75%", "100%"],
      taskPriorityList: ["High", "Medium", "Low"],
      isDone: false,
      tabs: ["toDoList", "tickets", "attachments", "history"],
      currentTab: "toDoList",
    };
  },
  props: {
    editTaskDetails: {
      type: Object,
      default: () => {},
    },
    projectDetails: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    async deleteTask() {
      const taskDetails = {
        task_id: this.editTaskDetails.task_id,
        project_id: this.editTaskDetails.project_id,
        status: "inactive",
      };

      const updatetaskResp = await this.$store.dispatch(
        "updateProjectTask",
        taskDetails,
      );
      if (updatetaskResp.status === "error") {
        console.log("error in task update");
      } else {
        this.editTaskDetails = Object.assign(this.editTaskDetails, taskDetails);
        this.$emit("taskDeleted", updatetaskResp);
      }
    },
    taskDoneDialog() {
      this.doneDialog = true;
    },
    async completeTask() {
      this.isDone = true;
      await this.updatetask();
      this.doneDialog = false;
    },
    closeDoneDialog() {
      this.doneDialog = false;
      this.isDone = false;
    },
    toggle() {
      if (this.selectAllAssignees) {
        this.selectedTaskAssignees = [];
      } else {
        this.selectedTaskAssignees = this.taskAssigneesList.map((element) => {
          return element.user_id;
        });
      }
    },
    toggleTicket() {
      if (this.selectAllTickets) {
        this.selectedAssociateTicket = [];
      } else {
        this.selectedAssociateTicket = this.associateTicketList.map(
          (element) => {
            return element.ticket_id;
          },
        );
      }
    },
    closeDialog() {
      this.selectedTaskAssignees = [];
      this.selectedAssociateTicket = [];
      this.description = "";
      this.taskName = "";
      this.menu1 = false;
      this.menu2 = false;
      this.scheduledStartDate = "";
      this.scheduledEndDate = "";
      this.date = "";
      this.endDate = "";
      this.selectedPriority = "";
      this.isDone = false;
      this.$emit("closeTaskDialog");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("T")[0].split("-");
      return month ? `${month}/${day}/${year}` : date;
    },
    async getTicketData() {
      const apiFilter = {};
      apiFilter.project_id = this.editTaskDetails.project_id;
      let response = await api.ticket.getTickets(apiFilter);
      for (let ticket of response.tickets) {
        ticket.fullAddress =
          ticket.street_address +
          " " +
          ticket.place +
          " " +
          ticket.county +
          " " +
          ticket.state;
        ticket.center_name = this.getCenterName(ticket.center_id);
      }
      this.tickets = response.tickets.filter((ticket) => {
        return !ticket.center_ticket_id.startsWith("S");
      });
      this.$store.commit("setTickets", [...response.tickets]);
    },
    getCenterName(centerId) {
      let center = this.centers.find((center) => center.center_id === centerId);
      return center ? center.center_name : "";
    },
    async updatetask() {
      const taskDetails = {
        task_id: this.editTaskDetails.task_id,
        project_id: this.editTaskDetails.project_id,
        task_name: this.taskName,
        priority: this.selectedPriority,
        progress: this.progressValue.slice(0, -1),
      };
      if (this.date) taskDetails.start_date = this.date;
      if (this.endDate) taskDetails.end_date = this.endDate;
      if (taskDetails.progress === "100") {
        taskDetails.is_complete = true;
      } else {
        taskDetails.is_complete = false;
      }
      if (
        this.selectedTaskAssignees &&
        this.selectedTaskAssignees.length !== 0
      ) {
        taskDetails.assignee_ids = this.selectedTaskAssignees.join(",");
      } else {
        taskDetails.assignee_ids = "";
      }
      if (
        this.selectedAssociateTicket &&
        this.selectedAssociateTicket.length !== 0
      ) {
        taskDetails.associated_ticket_ids =
          this.selectedAssociateTicket.join(",");
      } else {
        taskDetails.associated_ticket_ids = "";
      }
      if (this.description && this.description.length !== 0)
        taskDetails.task_desc = this.description;
      const updatetaskResp = await this.$store.dispatch(
        "updateProjectTask",
        taskDetails,
      );
      if (updatetaskResp.status === "error") {
        console.log("error in task update");
      } else {
        this.$emit("hideTaskDialog", Object.assign({}, taskDetails));
      }
    },
    emptyEndDate() {
      this.scheduledEndDate = "";
      this.endDate = "";
    },
    changeTab(tab) {
      this.currentTab = tab;
    },
  },
  computed: {
    projectInfo() {
      const project = this.$store.state.Project.project.find((project) => {
        return project.project_id === this.project_id;
      });

      return project;
    },
    selectAllAssignees() {
      return (
        this.selectedTaskAssignees?.length === this.taskAssigneesList?.length
      );
    },
    selectAllTickets() {
      return (
        this.selectedAssociateTicket?.length ===
        this.associateTicketList?.length
      );
    },
    dateFormattedErrors() {
      const errors = [];
      if (!this.$v.scheduledStartDate.$dirty) return errors;
      !this.$v.scheduledStartDate.required &&
        errors.push(this.$t("taskStartDateRequired"));
      return errors;
    },
    endDateFormattedErrors() {
      const errors = [];
      if (!this.$v.scheduledEndDate.$dirty) return errors;
      !this.$v.scheduledEndDate.required &&
        errors.push(this.$t("taskEndDateRequired"));
      return errors;
    },
    taskNameErrors() {
      const errors = [];
      if (!this.$v.taskName.$dirty) return errors;
      !this.$v.taskName.required && errors.push(this.$t("taskNameRequired"));
      return errors;
    },
    minDate() {
      return this.projectInfo.start_date
        ? moment(new Date(this.formatDate(this.projectInfo.start_date))).format(
            "YYYY-MM-DD",
          )
        : new Date().toISOString().slice(0, 10);
    },
    minEndDate() {
      var tempBeginDate = new Date(this.date);
      var beginDate = new Date(tempBeginDate.setDate(tempBeginDate.getDate()));
      return beginDate.toISOString().slice(0, 10);
    },
    isEditMode() {
      return this.taskDetails && Object.keys(this.taskDetails).length > 0;
    },
    taskAssigneesList() {
      const companyUsers = this.$store.state.User.companyUsers;
      let assignees = [];
      if (companyUsers.length) {
        assignees = companyUsers.filter((user) => {
          return user.status === "active";
        });
      }
      return assignees;
    },
    associateTicketList() {
      const ticketData = this.$store.state.Ticket;
      let ticketList = [];

      if (ticketData && ticketData.tickets) {
        ticketList = Object.entries(ticketData.tickets).map(([key, value]) => {
          let ticketNumber = "";
          if (value.revision)
            ticketNumber = `${value.center_ticket_id}-${value.revision}`;
          else ticketNumber = `${value.center_ticket_id}`;
          return {
            ticket_number: ticketNumber,
            ticket_id: value.ticket_id,
            center_ticket_id: value.center_ticket_id,
            project_id: value.project_id,
          };
        });
        ticketList = ticketList.filter((ticket) => {
          return (
            !ticket.center_ticket_id.startsWith("S") &&
            ticket.project_id &&
            this.projectDetails &&
            this.projectDetails.project_id &&
            ticket.project_id == this.projectDetails.project_id
          );
        });
      }
      return ticketList;
    },
  },
  watch: {
    date(val) {
      this.scheduledStartDate = this.formatDate(this.date);
    },
    endDate(val) {
      this.scheduledEndDate = this.formatDate(this.endDate);
    },
    progressValue() {
      if (this.progressValue !== "100%") this.isDone = false;
      else if (this.progressValue === "100%") {
        this.isDone = true;
        // this.taskDoneDialog();
      }
    },
    selectedTaskAssignees() {
      if (!this.selectAllAssignees) this.allAssignees = false;
    },
    searchInput() {
      if (this.searchInput && this.searchInput.length > 0) this.showAll = false;
      else this.showAll = true;
    },
    selectedAssociateTicket() {
      if (!this.selectAllTickets) this.allTickets = false;
    },
    searchTicket() {
      if (this.searchTicket && this.searchTicket.length > 0)
        this.showAllTickets = false;
      else this.showAllTickets = true;
    },
  },
  async created() {
    if (this.editTaskDetails && Object.keys(this.editTaskDetails).length > 0) {
      this.task_id = this.editTaskDetails.task_id;
      this.project_id = this.editTaskDetails.project_id;
      this.taskName = this.editTaskDetails.task_name;
      this.selectedPriority = this.editTaskDetails.priority;
      this.progressValue = this.editTaskDetails.progress
        ? this.editTaskDetails.progress
        : "0%";
      if (this.editTaskDetails.start_date)
        this.date = this.editTaskDetails.start_date;
      if (this.editTaskDetails.end_date)
        this.endDate = this.editTaskDetails.end_date;
      if (this.editTaskDetails.task_desc)
        this.description = this.editTaskDetails.task_desc;
      if (this.editTaskDetails.is_complete)
        this.isDone = this.editTaskDetails.is_complete;
      this.selectedTaskAssignees = this.editTaskDetails.assignee_ids;
      this.selectedAssociateTicket = this.editTaskDetails.associated_ticket_ids;
      if (this.selectAllTickets) this.allTickets = true;
      if (this.selectAllAssignees) this.allAssignees = true;
      if (
        this.editTaskDetails.is_complete &&
        this.editTaskDetails.progress === "100%"
      )
        this.doneDialog = false;

      if (this.editTaskDetails.created_at) {
        const [year, month, day] = this.editTaskDetails.created_at
          .split("T")[0]
          .split("-");
        this.createdDate = `${month.padStart(2, "0")}/${day.padStart(
          2,
          "0",
        )}/${year}`;
      } else {
        this.createdDate = this.editTaskDetails.start_date;
      }
    }
    await this.getTicketData();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/settings.scss";
.appbar-header.white {
  border: 1px solid $grey-lighten-2 !important;
}
.v-select.v-input--dense .v-chip {
  margin: 8px 4px 0 4px;
}
.flexcard {
  display: flex;
  flex-direction: column;
}
</style>
<style lang="scss">
.select-view {
  .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot {
    width: 100px;
    min-height: 15px;
    height: 32px;
    padding: 0px;
  }
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-left: 8px;
    padding-right: 6px;
  }
}
.v-select.fit {
  width: min-content;
}
.v-select.fit .v-select__selection--comma {
  text-overflow: unset;
}
</style>
