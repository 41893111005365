var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "overflow-auto",
      class: _vm.$vuetify.breakpoint.mdAndUp ? "flexcard" : "",
      attrs: { flat: "", tile: "", height: "100%" },
    },
    [
      _c(
        "v-card-title",
        { staticClass: "pb-2", staticStyle: { "padding-left": "15px" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", [_vm._v(_vm._s(_vm.taskName))]),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-end py-0 mt-0",
                  attrs: { cols: "1" },
                },
                [
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "my-auto",
                          attrs: { "aria-label": "cross", icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.closeDialog.apply(null, arguments)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    : _c(
                        "v-btn",
                        {
                          staticClass: "my-auto",
                          attrs: { "aria-label": "delete", icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.deleteDialog = true
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-delete-outline")])],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "pa-0 grow" },
        [
          _c(
            "v-container",
            { staticClass: "px-4 pt-0 mb-16" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", "align-content": "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-progress-circular", {
                        staticClass: "mt-1",
                        attrs: {
                          rotate: -90,
                          size: 30,
                          width: 3,
                          value: _vm.progressValue,
                          color: "green",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "select-view", attrs: { cols: "auto" } },
                    [
                      _c("v-select", {
                        staticClass: "pa-0 fit",
                        attrs: {
                          items: _vm.taskProgressList,
                          "menu-props": { bottom: true, offsetY: true },
                          solo: "",
                          flat: "",
                          dense: "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.progressValue,
                          callback: function ($$v) {
                            _vm.progressValue = $$v
                          },
                          expression: "progressValue",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "mt-1", attrs: { cols: "auto" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "mr-2 text-subtitle-1 text--darken-2 font-weight-medium",
                        },
                        [_vm._v("|")]
                      ),
                      _c("v-icon", { attrs: { size: "18px" } }, [
                        _vm._v("mdi-calendar-blank-outline"),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "text--darken-2 font-weight-medium",
                          class: _vm.$vuetify.breakpoint.mdAndUp ? "mx-2" : "",
                          style: _vm.$vuetify.breakpoint.mdAndUp
                            ? "font-size:14px"
                            : "font-size:10px",
                        },
                        [_vm._v(_vm._s(_vm.$t("created")) + ":")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-body-2 text--darken-2 font-weight-bold",
                        },
                        [_vm._v(_vm._s(_vm.createdDate))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "my-2" }),
              _c(
                "v-row",
                { staticClass: "pt-4", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                    },
                    [_vm._v(_vm._s(_vm.$t("editDetails")))]
                  ),
                ]
              ),
              _c(
                "v-row",
                { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      required: "",
                      disabled: !_vm.checkPermission("createTask"),
                      "error-messages": _vm.taskNameErrors,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.taskName.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.taskName.$touch()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(" *"),
                            ]),
                            _vm._v(" " + _vm._s(_vm.$t("taskName")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.taskName,
                      callback: function ($$v) {
                        _vm.taskName = $$v
                      },
                      expression: "taskName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.taskAssigneesList,
                      "menu-props": { bottom: true, offsetY: true },
                      label: "Assigned To",
                      outlined: "",
                      dense: "",
                      required: "",
                      disabled: !_vm.checkPermission("createTask"),
                      multiple: "",
                      "item-text": "name",
                      "item-value": "user_id",
                      "search-input": _vm.searchInput,
                    },
                    on: {
                      input: function ($event) {
                        _vm.searchInput = null
                      },
                      "update:searchInput": function ($event) {
                        _vm.searchInput = $event
                      },
                      "update:search-input": function ($event) {
                        _vm.searchInput = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm.taskAssigneesList.length !== 0
                          ? {
                              key: "prepend-item",
                              fn: function () {
                                return [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "prepend-inner-icon": "mdi-magnify",
                                          label: _vm.$t("searchName"),
                                          "single-line": "",
                                          "hide-details": "",
                                          flat: "",
                                          variant: "underlined",
                                          dense: "",
                                          "clear-icon": "mdi-close-circle",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.searchInput,
                                          callback: function ($$v) {
                                            _vm.searchInput = $$v
                                          },
                                          expression: "searchInput",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.showAll
                                    ? _c(
                                        "v-list-item",
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "my-3",
                                            attrs: {
                                              "hide-details": "",
                                              label: "Select All",
                                            },
                                            on: { click: _vm.toggle },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ml-6 font-weight-medium grey--text text--darken-4 text-subtitle-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t("all")
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              1003791988
                                            ),
                                            model: {
                                              value: _vm.allAssignees,
                                              callback: function ($$v) {
                                                _vm.allAssignees = $$v
                                              },
                                              expression: "allAssignees",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                        {
                          key: "selection",
                          fn: function (ref) {
                            var attrs = ref.attrs
                            var item = ref.item
                            var parent = ref.parent
                            var selected = ref.selected
                            return [
                              _c(
                                "v-chip",
                                _vm._b(
                                  {
                                    attrs: {
                                      size: "small",
                                      density: "compact",
                                      "input-value": selected,
                                    },
                                  },
                                  "v-chip",
                                  attrs,
                                  false
                                ),
                                [
                                  _c("span", { staticClass: "pr-2" }, [
                                    _vm._v(" " + _vm._s(item.name)),
                                  ]),
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return parent.selectItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" mdi-close-circle ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.selectedTaskAssignees,
                      callback: function ($$v) {
                        _vm.selectedTaskAssignees = $$v
                      },
                      expression: "selectedTaskAssignees",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.taskPriorityList,
                      "menu-props": { bottom: true, offsetY: true },
                      label: "Priority",
                      outlined: "",
                      disabled: !_vm.checkPermission("createTask"),
                      dense: "",
                      required: "",
                    },
                    model: {
                      value: _vm.selectedPriority,
                      callback: function ($$v) {
                        _vm.selectedPriority = $$v
                      },
                      expression: "selectedPriority",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.associateTicketList,
                      "menu-props": { bottom: true, offsetY: true },
                      label: "Associate Ticket",
                      outlined: "",
                      dense: "",
                      required: "",
                      multiple: "",
                      "item-text": "ticket_number",
                      "item-value": "ticket_id",
                      "search-input": _vm.searchTicket,
                    },
                    on: {
                      input: function ($event) {
                        _vm.searchTicket = null
                      },
                      "update:searchInput": function ($event) {
                        _vm.searchTicket = $event
                      },
                      "update:search-input": function ($event) {
                        _vm.searchTicket = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm.associateTicketList.length !== 0
                          ? {
                              key: "prepend-item",
                              fn: function () {
                                return [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "prepend-inner-icon": "mdi-magnify",
                                          label: _vm.$t("searchticketNumber"),
                                          "single-line": "",
                                          "hide-details": "",
                                          flat: "",
                                          dense: "",
                                          variant: "underlined",
                                          "clear-icon": "mdi-close-circle",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.searchTicket,
                                          callback: function ($$v) {
                                            _vm.searchTicket = $$v
                                          },
                                          expression: "searchTicket",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.showAllTickets
                                    ? _c(
                                        "v-list-item",
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "my-3",
                                            attrs: {
                                              "hide-details": "",
                                              label: "Select All",
                                            },
                                            on: { click: _vm.toggleTicket },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ml-6 font-weight-medium grey--text text--darken-4 text-subtitle-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t("all")
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              1003791988
                                            ),
                                            model: {
                                              value: _vm.allTickets,
                                              callback: function ($$v) {
                                                _vm.allTickets = $$v
                                              },
                                              expression: "allTickets",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                        {
                          key: "selection",
                          fn: function (ref) {
                            var attrs = ref.attrs
                            var item = ref.item
                            var parent = ref.parent
                            var selected = ref.selected
                            return [
                              _c(
                                "v-chip",
                                _vm._b(
                                  {
                                    attrs: {
                                      size: "small",
                                      density: "compact",
                                      "input-value": selected,
                                    },
                                  },
                                  "v-chip",
                                  attrs,
                                  false
                                ),
                                [
                                  _c("span", { staticClass: "pr-2" }, [
                                    _vm._v(
                                      " " + _vm._s(item.ticket_number) + " "
                                    ),
                                  ]),
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return parent.selectItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" mdi-close-circle ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.selectedAssociateTicket,
                      callback: function ($$v) {
                        _vm.selectedAssociateTicket = $$v
                      },
                      expression: "selectedAssociateTicket",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "", justify: "space-between" } },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: _vm.$vuetify.breakpoint.mdAndUp ? "auto" : "12",
                      },
                    },
                    [
                      _c(
                        "v-menu",
                        {
                          ref: "menu1",
                          attrs: {
                            "close-on-content-click": false,
                            transition: "scale-transition",
                            "offset-y": "",
                            "max-width": "290px",
                            "min-width": "auto",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            "persistent-hint": "",
                                            outlined: "",
                                            dense: "",
                                            "prepend-inner-icon":
                                              "mdi-calendar",
                                            "hide-details": "",
                                            disabled:
                                              !_vm.checkPermission(
                                                "createTask"
                                              ),
                                          },
                                          on: { change: _vm.emptyEndDate },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("startDate")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.scheduledStartDate,
                                            callback: function ($$v) {
                                              _vm.scheduledStartDate = $$v
                                            },
                                            expression: "scheduledStartDate",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.menu1,
                            callback: function ($$v) {
                              _vm.menu1 = $$v
                            },
                            expression: "menu1",
                          },
                        },
                        [
                          _c("v-date-picker", {
                            attrs: { "no-title": "", min: _vm.minDate },
                            on: {
                              change: _vm.emptyEndDate,
                              input: function ($event) {
                                _vm.menu1 = false
                              },
                            },
                            model: {
                              value: _vm.date,
                              callback: function ($$v) {
                                _vm.date = $$v
                              },
                              expression: "date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.mdAndUp ? "" : "mt-4",
                      attrs: {
                        cols: _vm.$vuetify.breakpoint.mdAndUp ? "auto" : "12",
                      },
                    },
                    [
                      _c(
                        "v-menu",
                        {
                          ref: "menu2",
                          attrs: {
                            "close-on-content-click": false,
                            transition: "scale-transition",
                            "offset-y": "",
                            "max-width": "290px",
                            "min-width": "auto",
                            "nudge-left": "60px",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            "persistent-hint": "",
                                            outlined: "",
                                            dense: "",
                                            "prepend-inner-icon":
                                              "mdi-calendar",
                                            "hide-details": "",
                                            disabled:
                                              !_vm.checkPermission(
                                                "createTask"
                                              ),
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("endDate")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.scheduledEndDate,
                                            callback: function ($$v) {
                                              _vm.scheduledEndDate = $$v
                                            },
                                            expression: "scheduledEndDate",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.menu2,
                            callback: function ($$v) {
                              _vm.menu2 = $$v
                            },
                            expression: "menu2",
                          },
                        },
                        [
                          _c("v-date-picker", {
                            attrs: {
                              "no-title": "",
                              min: _vm.date ? _vm.minEndDate : "",
                            },
                            on: {
                              input: function ($event) {
                                _vm.menu2 = false
                              },
                            },
                            model: {
                              value: _vm.endDate,
                              callback: function ($$v) {
                                _vm.endDate = $$v
                              },
                              expression: "endDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-6", attrs: { "no-gutters": "" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      rows: "3",
                      outlined: "",
                      "row-height": "20",
                      "no-resize": "",
                      dense: "",
                      "hide-details": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(" " + _vm._s(_vm.$t("description")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description = $$v
                      },
                      expression: "description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-app-bar",
                    {
                      staticClass: "appbar-header",
                      attrs: { elevation: "0", color: "white", height: "40" },
                    },
                    [
                      _c(
                        "v-tabs",
                        _vm._l(_vm.tabs, function (key, index) {
                          return _c(
                            "v-tab",
                            {
                              key: index,
                              staticClass: "text-none",
                              class: _vm.$vuetify.breakpoint.mdAndUp
                                ? "pl-8"
                                : "pl-5",
                              on: {
                                click: function ($event) {
                                  return _vm.changeTab(key)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t(key)) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-card",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.currentTab === "toDoList",
                              expression: "currentTab === 'toDoList'",
                            },
                          ],
                          attrs: { elevation: "0" },
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pa-0" },
                            [
                              _c("ToDoTask", {
                                attrs: {
                                  "edit-task-details": _vm.editTaskDetails,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.currentTab === "tickets",
                              expression: "currentTab === 'tickets'",
                            },
                          ],
                        },
                        [
                          _c("v-card-text", [
                            _c("p", [_vm._v("Content for Tickets")]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.currentTab === "attachments",
                              expression: "currentTab === 'attachments'",
                            },
                          ],
                        },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("UploadFiles", {
                                attrs: {
                                  "reference-id": _vm.task_id,
                                  "show-header": false,
                                  "reference-type": "task",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.currentTab === "history"
                        ? _c(
                            "v-card",
                            [
                              _c("TaskHistory", {
                                attrs: { "task-id": _vm.task_id },
                              }),
                              _c("v-card-text"),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-2 mx-4" }),
      _vm.checkPermission("createTask")
        ? _c(
            "v-card-actions",
            { staticClass: "mx-2 mb-3" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2 text-none rounded-lg elevation-0",
                  attrs: { color: "error", outlined: "", width: "110" },
                  on: {
                    click: function ($event) {
                      _vm.deleteDialog = true
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("deleteTask")) + " ")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-2 text-none rounded-lg elevation-0",
                  attrs: { color: "primary", outlined: "", width: "90" },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "text-none rounded-lg elevation-0",
                  attrs: {
                    color: "primary",
                    width: "160",
                    depressed: "",
                    disabled: _vm.$v.$invalid,
                  },
                  on: { click: _vm.updatetask },
                },
                [
                  _c("span", { staticClass: "white--text text-none" }, [
                    _vm._v(_vm._s(_vm.$t("updateChanges"))),
                  ]),
                ]
              ),
            ],
            1
          )
        : _c(
            "v-card-actions",
            { staticClass: "mx-2 mb-3" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2 text-none rounded-lg elevation-0",
                  attrs: { color: "primary", outlined: "", width: "90" },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text-none rounded-lg elevation-0",
                  attrs: {
                    color: "primary",
                    width: "90",
                    depressed: "",
                    disabled: _vm.$v.$invalid,
                  },
                  on: { click: _vm.updatetask },
                },
                [
                  _c("span", { staticClass: "white--text text-none" }, [
                    _vm._v(_vm._s(_vm.$t("update"))),
                  ]),
                ]
              ),
            ],
            1
          ),
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-bottom-navigation",
            {
              staticClass: "px-2 py-4",
              attrs: { fixed: "", horizontal: "", height: "70" },
            },
            [
              _c(
                "v-row",
                { attrs: { justify: "space-between", "no-gutters": "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2 text-none rounded-lg elevation-0",
                      attrs: { color: "primary", outlined: "", width: "50%" },
                      on: { click: _vm.closeDialog },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.checkPermission("createTask")
                              ? _vm.$t("cancel")
                              : _vm.$t("close")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "text-none font-weight-regular grey--text rounded-lg elevation-0",
                      attrs: {
                        color: "primary",
                        width: "50%",
                        depressed: "",
                        disabled: _vm.$v.$invalid,
                      },
                      on: { click: _vm.updatetask },
                    },
                    [
                      _c("span", { staticClass: "white--text text-none" }, [
                        _vm._v(
                          _vm._s(
                            _vm.checkPermission("createTask")
                              ? _vm.$t("updateChanges")
                              : _vm.$t("update")
                          )
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("TaskDoneDialog", {
        attrs: { dialog: _vm.doneDialog },
        on: {
          completeTask: _vm.completeTask,
          closeDialog: _vm.closeDoneDialog,
        },
      }),
      _vm.deleteDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "420",
                "max-height": "400",
                persistent: "",
              },
              model: {
                value: _vm.deleteDialog,
                callback: function ($$v) {
                  _vm.deleteDialog = $$v
                },
                expression: "deleteDialog",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "overflow-hidden" },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0 ml-4" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "col-10 px-0 grey--text text--darken-4 text-h6 text-truncate",
                        },
                        [_vm._v(_vm._s(_vm.$t("deleteTask")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "col-2 my-auto",
                          attrs: { "aria-label": "cross", icon: "" },
                          on: { click: _vm.closeDeleteDialog },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-row", [_c("v-divider")], 1),
                  _c("v-row", { staticClass: "ma-0 ml-4 mb-10 pt-4" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "grey--text text--darken-4 text-subtitle-1 font-weight-regular",
                      },
                      [_vm._v(_vm._s(_vm.$t("deleteTaskMessage")))]
                    ),
                  ]),
                  _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none rounded-lg elevation-0",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            depressed: "",
                            width: "90",
                          },
                          on: { click: _vm.closeDeleteDialog },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "primary text-none rounded-lg elevation-0",
                          attrs: { width: "90", depressed: "" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteTask()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("delete")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }